import subscriptions from "./subscriptions";

let authToken = false;
let listeners = [];
let isRunning = false;
let lastDiffId = -1;
let xhr;
let diffIsReadyResolve = null;
let diffIsReadyReject = null;
const setDiffIsReady = () => new Promise((resolve, reject) => {
    diffIsReadyResolve = resolve;
    diffIsReadyReject = reject;
  });

let diffIsReady = {promise: setDiffIsReady()};
const onDiffIsReady = () => diffIsReady.promise;

const diffInstance = {
  getParams: function() {
    return {
      diffid: lastDiffId,
      iconformat: "id",
      difflimit: 500,
      auth: authToken
    };
  },
  handleEvents: function(ret) {
    lastDiffId = ret.diffid;

    processDiffEvents(ret.entries);
    setTimeout(
      subscriptions.setState.bind(subscriptions, "diff", "ready"),
      ret.entries.length
    );
  }
};

function processDiffEvents(entries) {
  listeners.forEach(listener => listener(entries));
}

function listen(token, callback) {

  if (authToken !== token) {
    authToken = token;
  }

  if (typeof callback === "function") {
    listeners.push(callback);
  }

  start();

  return (skipStop) => {
    if (typeof callback !== "function") {
      return;
    }

    listeners.splice(listeners.indexOf(callback));

    console.log("removed diff listener", `#${listeners.length}`);

    if (!listeners.length && !skipStop) {
      stop();
    }
  };
}

function start() {
  if (isRunning) {
    return;
  }

  isRunning = true;
  subscriptions.add("diff", diffInstance);

  const onErrorDiffRequest = () => {
    isRunning = false;
    diffIsReadyReject();
  }

  xhr = pCloudGlobals.apiMethod("diff", { last: 0, auth: authToken }, function(ret) {
    lastDiffId = ret.diffid;

    subscriptions.setState("diff", "ready");
    
    subscriptions.onSentSubscribeRequest.then(() => {
      diffIsReadyResolve();
    });

    xhr = false;
  }, 
  { 
    errorCallback: onErrorDiffRequest,
    onXhrError: onErrorDiffRequest
  });
}

function stop() {
  if (xhr) {
    xhr.abort();
  }

  lastDiffId = -1;
  subscriptions.remove("diff");
  isRunning = false;
  diffIsReady.promise = setDiffIsReady();
}

export default { listen, stop, onDiffIsReady };