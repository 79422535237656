import { setcookie, setItemToLocalStorage } from "./utils";

export const API_SERVER = "apipass.pcloud.com";
export const LOGIN_FAILD = 2000;
export const API_SERVER_HTTPS = "https://apipass.pcloud.com/";

export const passApiMethod = async (method = "", data = {}, opts, successCallback = () => {}) => {
  const queryString = API_SERVER_HTTPS + method + (opts && opts.method === "GET" ? `?${new URLSearchParams(data)}` : "");
  const formData = new FormData();

  for (const name in data) {
    formData.append(name, data[name]);
  }

  const fetchOptions = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached,
    headers: {
      Accept: "application/json",
    },
    ...opts,
  };

  if (fetchOptions && fetchOptions.method === "POST") {
    fetchOptions.body = formData;
  }

  const response = await fetch(queryString, fetchOptions);
  const responseResult = await response.json();

  if (responseResult.result === LOGIN_FAILD) {
    setItemToLocalStorage({ locationid: "" });
    setItemToLocalStorage({ pcauth: "" });
    setItemToLocalStorage({ passtoken: "" });
    setItemToLocalStorage({ restrictedPassToken: "" });
    setcookie("restrictedPassToken", "", -1);
    setcookie("passtoken", "", -1);

    if (typeof localStorage !== "undefined") {
      localStorage.clear();
    }
  } else {
    successCallback(responseResult);
  }

  return responseResult;
};
