import React from "react";
import { __ } from "./utils";
import {
  STRIPE_PK,
  SAFECHARGE_MERCHANT_SITE_ID,
  SAFECHARGE_MERCHANT_ID,
  SAFECHARGE_ENV
} from "../../web-shared/constants";
import { signToCurrency } from "@pcloud/web-utilities/dist/payments/constants.payments";
// import { sfc } from "../../web-shared/insertSafechargeForm";
import { removeCyclic } from "../../web-shared/utils";

import diffs from "../lib/diffs";

import VisaSVG from "../svg/card-logo/visa.svg";
import MasterCardSVG from "../svg/card-logo/mastercard.svg";
// import MaestroSVG from "../svg/card-logo/maestro.svg";
import AmexCardSVG from "../svg/card-logo/amex.svg";
import DinersCardSVG from "../svg/card-logo/diners.svg";
import JCBSVG from "../svg/card-logo/jcb.svg";
import DiscoverSVG from "../svg/card-logo/discover.svg";
import PaypalSVG from "../svg/paypal-logo.svg";
import DefaultCardSVG from "../svg/card-logo/card-default.svg";
import GooglePlaySVG from "../svg/google-play.svg";
import ItunesSVG from "../svg/itunes.svg";
import VivacomSVG from "../svg/vivacom-logo.svg";

const EU_LOCATIONID = 2;
const US_LOCATIONID = 1;

const paymentApiUrls = {};
paymentApiUrls[EU_LOCATIONID] = "eqapayments.pcloud.com";
paymentApiUrls[US_LOCATIONID] = "qabraintree.pcloud.com";

export const getPaymentsApiUrl = () => {
  return paymentApiUrls[pCloudGlobals.apiConfig.locationid];
};

let passedOpts;
let errorCallback;

export function SubscriptionUpdate({
  provider,
  period,
  discountcode,
  billingAddress,
  userAuth,
  products = [],
  traffic,
  storage,
  toggleLoader,
  showPaymentError,
  providerSourceId,
  newCC,
  authorizeCard,
  isBusinessTrial = false,
  downgrade = false,
  onBraintreeCreatePayment = () => {}
}) {
  const apiMethods = {
    stripe: "paystripe",
    safecharge: "paysafecharge",
    paypal: isBusinessTrial ? "subscription/createbusiness" : "purchase/create",
    adyen: "payadyen"
  };

  this.method = apiMethods[provider];
  this.checkout = null;
  this.safeChargeCCToken = "";
  this.amountToPay = 1;
  this.userTokenId = "";
  this.orderid = null;

  this.paymentInfo = {
    auth: userAuth,
    products: products.join(","),
    period,
    discountcode
  };

  if (traffic !== 0) {
    this.paymentInfo.traffic = traffic;
  }

  if (storage !== 0) {
    this.paymentInfo.storage = storage;
  }

  if (downgrade) {
    this.paymentInfo.isdowngrade = 1;
  }

  this.errors = {
    "1120": __("discount_error_1120"),
    "1126": "Invalid usage.",
    "1131": "Please provide ‘period’.",
    "2247": __("discount_error_2247"),
    "2248": __("discount_error_2248"),
    "2249": __("discount_error_2249"),
    "2250": __("discount_error_2250"),
    "2252": __("discount_error_2252"),
    "2253": __("discount_error_2253"),
    "2256": "You can’t use your own referral code.",
    "2257": "You already bought this lifetime product.",
    "2289": "This custom plan can’t be bought.",
    "1101": "Invalid request.",
    "1056": __("payment_error_1056"),
    "2209": __("payment_error_2209"),
    "2215": __("payment_error_2215"),
    "2273": __("payment_method_limit_reached"),
    "93107": __("payment_error_93107"),
    "93109": __("payment_error_93109"),
    "82901": __("payment_error_82901"),
    "92910": __("payment_error_92910"),
    "92911": __("payment_error_92911"),
    "93108": __("payment_error_93108"),
    "81901": __("payment_error_81901"),
    "81905": __("payment_error_81905"),
    "91609": __("payment_error_91609"),
    api_connection_error: __("stripe_api_connection_error"),
    api_error: __("stripe_api_error"),
    authentication_error: __("stripe_authentication_error"),
    invalid_request_error: __("stripe_invalid_request_error"),
    rate_limit_error: __("stripe_rate_limit_error"),
    invalid_number: __("stripe_invalid_number"),
    invalid_expiry_month: __("stripe_invalid_expiry_month"),
    invalid_expiry_year: __("stripe_invalid_expiry_year"),
    invalid_cvc: __("stripe_invalid_cvc"),
    incorrect_number: __("stripe_incorrect_number"),
    expired_card: __("stripe_expired_card"),
    incorrect_cvc: __("stripe_incorrect_cvc"),
    incorrect_zip: __("stripe_incorrect_zip"),
    card_declined: __("stripe_card_declined"),
    processing_error: __("stripe_processing_error,"),
    missing: __("stripe_missing"),
    // triggered by window.sfc.createPayment in errorDescription field
    incorrect_card_number: __("stripe_incorrect_number"),
    xhr_error: __("payment_error_1056")
  };

  this.handlePaymenterror = (data) => {
    console.log("ERROR DATA", data);
    const translated = this.errors[data.reasoncode] || this.errors[data.reason] || this.errors[data.result]  || this.errors[data.error] || this.errors[data.code] || this.errors[data.errorDescription];
    let message;
    if (translated) {
      message = translated;
    } else {
      if (data.reason && data.reason.indexOf("TRANSID") != -1) {
        message = __("payment_error_unhandled");
      } else {
        message = data.reason || data.error || data.errorDescription || data.result || data.message;
      }

      if (message instanceof Object) {
        message = message.message || message.code
      }
    }
    
    toggleLoader("hide");
    showPaymentError(message);
  };

  this.onPaymentSuccess = (res, opt) => {
    if (opt.callback && typeof opt.callback == "function") {
      opt.callback(res, () => {}, (data) => {
        this.handlePaymenterror(data);
      });
    } else {
      toggleLoader("hide", "success");
    }
  };

  this.createCardData = (
    numberProp,
    expMonthProp,
    expYearProp,
    nameProp,
    cvcProp
  ) => {
    if (newCC) {
      const forCardData = {};
      forCardData[numberProp] = newCC.cardNumber;
      forCardData[expMonthProp] = newCC.expirationMonth;
      forCardData[expYearProp] = newCC.expirationYear;
      forCardData[nameProp] = newCC.cardHolderName;
      forCardData[cvcProp] = newCC.CVV;
      return forCardData;
    }
  };

  this.paymentApiCall = opt => {
    toggleLoader("show");
    apiCall(
      this.method,
      this.paymentInfo,
      data => {
        if (
          data.redirecturl || (data.action && data.action.type === "redirect")
        ) {
          window.showSafeChargeError = message => {
            this.onPaymentError(message);
          };
        } else {
          this.onPaymentSuccess(data, opt);
        }
      },
      ret => {
        this.handlePaymenterror(ret);
      },
      {
        onXhrError: (xhr, status, error) => {
          this.handlePaymenterror({reasoncode: "xhr_error", xhrError: {error: xhr, status, error}});
        }
      }
    );
  };

  this.createStripePayment = ({ newpaymentmethod, paymentmethodid, paymentintentsecret }, opt) => {
    const stripe = Stripe('pk_test_rqxCBCYuPZVO5zmwMeD0yEvk');
    let params = {};
    const self = this;
    
    // if (newpaymentmethod) {
    //   params = {
    //     payment_method_data: {
    //       card: { token: this.paymentInfo["token"] },
    //       billing_details: { name: newCC && newCC.cardHolderName }
    //     },
    //     save_payment_method: true,
    //     setup_future_usage: "off_session"
    //   }	
    // } 
    
    if (paymentmethodid) {
      params = {
        payment_method: paymentmethodid,
        setup_future_usage: "off_session"
      };
    } else {
      params = { 
        payment_method: {card: { token: this.paymentInfo["token"] }},
        setup_future_usage: "off_session"
      };
    }

    stripe.confirmCardPayment(paymentintentsecret, params)
    .then(function(result) {
      if (result.error) {
        self.handlePaymenterror(result);
      } else {
        self.onPaymentSuccess(result, opt);
      }
    });
  }

  this.confirmStripeCard = ({ newpaymentmethod, paymentmethodid, clientsecret }, opt) => {
    const stripe = Stripe('pk_test_rqxCBCYuPZVO5zmwMeD0yEvk');
    const params = {
      payment_method: {
        card: { token: this.paymentInfo["token"] }
      }
    }	
    const self = this;
    
    if (paymentmethodid && !newCC) {
      params.payment_method = paymentmethodid;
    }

    stripe.confirmCardSetup(clientsecret, params)
    .then(function(result) {
      if (result.error) {
        self.handlePaymenterror(result);
      } else  if (opt.shoudUpdateDefaultSource) {
          self.updateStripeDefaultSource(result.setupIntent.payment_method, {
            refresh: false,
            callback: opt.callback
          });
      } else if (opt.paymentCallback) {
        opt.paymentCallback();
      } else {
        self.onPaymentSuccess(result, opt);
      }
    });
  }

  this.updateStripeSubscription = async(opt, skipModal) => {
    toggleLoader("show");
    const self = this;

    // Wait for API to be ready to listen for user info change == successfully paid new plan.
    diffs.listen(userAuth);
    await diffs.onDiffIsReady().catch(err => console.error('Failed to create /subscribe request before payment.'));

    if (opt.changeCard) {
      self.paymentInfo.changecard = true;
    }

    if (opt.showPaymentConfirmationModal && !skipModal) {
      modalManager.open('PaymentConfirmationModal', {
        handleButtonClick: () => {
          self.updateStripeSubscription(opt, true);
        },
        handleModalClose: () => {
          toggleLoader("hide");
        }
      });

      return;
    }

    if (newCC) {
      this.setStripePaymentSource({
        paymentCallback: () => {
          delete self.paymentInfo.token;
          this.paymentApiCall({
            callback: (res) => {
              if (res.paymentintentsecret) {
                this.createStripePayment(res, opt);
              } else {
                opt.callback(res);
              }
            }
          });
        }
      });
    } else {
      this.paymentApiCall({
        callback: (res) => {
          if (res.paymentintentsecret) {
            this.createStripePayment(res, opt);
          } else {
            opt.callback(res);
          }
        }
      });
    }
  };

  this.updateStripeDefaultSource = (methodid, opt) => {
    toggleLoader("show");

    apiCall(
      "billing/stripe/setdefaultpaymentmethod",
      { auth: userAuth, methodid: methodid },
      res => {
       this.onPaymentSuccess(res, opt);
      },
      ret => {
        this.handlePaymenterror(ret);
      },
      {
        onXhrError: (xhr, status, error) => {
          this.handlePaymenterror({reasoncode: "xhr_error", xhrError: {error: xhr, status, error}});
        }
      }
    );
  };
  
  this.openSafechargeOrder = ({ successCallback }) => {
    if (pCloudGlobals && pCloudGlobals.apiMethod) {
      const openorderParams = authorizeCard ? {
        authorizecard: 1,
        auth: userAuth
      } : this.paymentInfo;

      pCloudGlobals.apiMethod('safecharge/openorder', openorderParams, data => {
        if (data.sessiontoken) {
          this.safeChargeCCToken = data.sessiontoken;
          this.userTokenId = data.usertokenid;
          this.amountToPay = data.amounttopay / 100;

          if (data.orderid) {
            this.orderid = data.orderid;
          }

          if (successCallback && typeof successCallback === "function") {
            successCallback(data);
          }
        }
      }, {
        errorCallback: (res) => {
          this.handlePaymenterror(res);
        },
        onXhrError: (xhr, status, error) => {
          this.handlePaymenterror({reasoncode: "xhr_error", xhrError: {error: xhr, status, error}});
        }
      })
    }
  }

  this.applySafechargeOrder = (opt) => {
    if (pCloudGlobals && pCloudGlobals.apiMethod) {
      pCloudGlobals.apiMethod('safecharge/applyorder', {
        auth: userAuth,
        orderid: this.orderid
      }, res => {
        // this.onPaymentSuccess(res, opt);
        opt.callback(res, () => {}, (data) => {
          this.handlePaymenterror(data);
        }, true);
      }, {
        errorCallback: (res) => {
          this.handlePaymenterror(res);
          toggleLoader("hide");
        },
        onXhrError: (xhr, status, error) => {
          this.handlePaymenterror({reasoncode: "xhr_error", xhrError: {error: xhr, status, error}});
        }
      })
    }
  }

  this.callSafechargePayment = (opt) => {
    if (!this.safeChargeCCToken) {
      return;
    }
    const currency = sessionStorage.pclCurrency || '$';
    let billingAddressFormatted; 
    
    if (billingAddress) {
      billingAddressFormatted = { ...billingAddress };
      billingAddressFormatted.country = billingAddressFormatted.country ? billingAddressFormatted.country.toUpperCase() : "LU";
    }

    const params = {
      sessionToken: this.safeChargeCCToken, //received from openOrder API 
      userTokenId: this.userTokenId,
      merchantId: SAFECHARGE_MERCHANT_ID.replace('SC_TEST_MERCHANT_ID_', ''),
      merchantSiteId : SAFECHARGE_MERCHANT_SITE_ID.replace('SC_TEST_MERCHANT_ID_', ''),
      clientUniqueId: Date.now(),
      currency: signToCurrency[currency],
      amount: this.amountToPay,
      billingAddress: billingAddressFormatted,
    }
    if (newCC && SFCardNumber) {
      removeCyclic(SFCardNumber);
      params.paymentOption = SFCardNumber;
      params.cardHolderName = newCC.cardHolderName
    } else if (providerSourceId) {
      params.paymentOption = { userPaymentOptionId: providerSourceId };
    }
    
    // Safecharge don't call the new callback, but the fist one provided
    // this we updated the opts used in the original callback
    passedOpts = opt;
    errorCallback = this.handlePaymenterror;

    window.sfc.createPayment(params, res => {
      if (res.result === "SUCCESS" || res.result === "APPROVED") {
        this.onPaymentSuccess(res, passedOpts);
      } else {
        errorCallback(res);
      }
    });
  }

  this.initSafechargePayment = async(opt) => {

    const self = this;
    passedOpts = opt;

    const afterOpenOrder = (res) => {
      if (res.amounttopay === 0 && res.orderid && !newCC && !SFCardNumber) {
        this.applySafechargeOrder(passedOpts);
      } else {
        if (window.sfc) {
          this.callSafechargePayment(passedOpts);
        } else {
          // Await for safecharge.js to be ready. Loaded inside insertSafechargeForm.js.
          window.addEventListener("sfcReady", () => this.callSafechargePayment(passedOpts), { once: true });
        }
      }
    }

    toggleLoader("show");

    // Wait for API to be ready to listen for user info change == successfully paid new plan.
    diffs.listen(userAuth);
    await diffs.onDiffIsReady().catch(err => console.error('Failed to create /subscribe request before payment.'));
    
    if (opt.showPaymentConfirmationModal) {
      modalManager.open('PaymentConfirmationModal', {
        handleButtonClick: () => {
          self.openSafechargeOrder({
            successCallback: (res) => afterOpenOrder(res),
          });
        },
        handleModalClose: () => {
          toggleLoader("hide");
        }
      });
    } else {
      self.openSafechargeOrder({
        successCallback: (res) => afterOpenOrder(res)
      });
    }
  };

  this.createBraintreePayment = async (opt, skipModal) => {
    const self = this;
    onBraintreeCreatePayment();
    
    if (opt.showPaymentConfirmationModal && !skipModal) {
      modalManager.open('PaymentConfirmationModal', {
        handleButtonClick: () => {
          self.createBraintreePayment(opt, true);
        },
        handleModalClose: () => {
          toggleLoader("hide");
        }
      });

      return;
    }

    toggleLoader("show");

    // Wait for API to be ready to listen for user info change == successfully paid new plan.
    diffs.listen(userAuth);
    await diffs.onDiffIsReady().catch(err => console.error('Failed to create /subscribe request before payment.'));

    apiCall(
      this.method,
      this.paymentInfo,
      res => {
        if (self.checkout) {
          self.checkout.teardown(() => {
            self.checkout = null;
          })
        }
        this.onPaymentSuccess(res, opt);
      },
      ret => {
        this.handlePaymenterror(ret);
      },
      {
        apiServer: getPaymentsApiUrl(),
        onXhrError: (xhr, status, error) => {
          this.handlePaymenterror({reasoncode: "xhr_error", xhrError: {error: xhr, status, error}});
        }
      }
    );
  };

  this.initBrainTree = (token, opt) => {
    const self = this;

    braintree.client.create({
      authorization: token
    }, function (clientErr, clientInstance) {
    
      // Stop if there was a problem creating the client.
      // This could happen if there is a network error or if the authorization
      // is invalid.
      if (clientErr) {
        console.error('Error creating client:', clientErr);
        return;
      }
    
      // Create a PayPal Checkout component.
      braintree.paypalCheckout.create({
        client: clientInstance
      }, function (paypalCheckoutErr, paypalCheckoutInstance) {
        paypalCheckoutInstance.loadPayPalSDK({
          vault: true
        }, function () {
          paypal.Buttons({
            fundingSource: paypal.FUNDING.PAYPAL,
            style: {
              color:  opt.color || 'blue',
              label:  opt.label || ''
            },
            createBillingAgreement: function () {
              return paypalCheckoutInstance.createPayment({
                flow: 'vault', // Required
              });
            },
    
            onApprove: function (data, actions) {
              toggleLoader("show");
              return paypalCheckoutInstance.tokenizePayment(data, function (err, payload) {
                if (payload.nonce) {
                  self.paymentInfo["nonce"] = payload.nonce;
                  if (isBusinessTrial) {
                    opt.callback({ nonce: payload.nonce })
                  } else {
                    self.createBraintreePayment(opt);
                  }
                } else {
                  showPaymentError(
                    __(
                      "something_went_wrong_refresh_and_try_again",
                      "Something went wrong. Refresh and try again."
                    )
                  );
                }
                // Submit `payload.nonce` to your server
              });
            },
    
            onCancel: function (data) {
              toggleLoader("hide");
            },
    
            onError: function (err) {
              console.error('PayPal error', err);
              toggleLoader("hide");
            }
          }).render('#paypal-button').then(function () {
            // The PayPal button will be rendered in an html element with the ID
            // `paypal-button`. This function will be called when the PayPal button
            // is set up and ready to be used
            if (opt.onReady) {
              opt.onReady();
            }
          });
    
        });
      });
    });
  };

  this.getTokenBraintree = (opt) => {
    // toggleLoader("show");
    apiCall(
      "./token/get",
      {auth: userAuth},
      res => {
        this.initBrainTree(res.token, opt);
      },
      ret => {
        this.handlePaymenterror(ret);
      },
      {
        apiServer: getPaymentsApiUrl(),
        onXhrError: (xhr, status, error) => {
          this.handlePaymenterror({reasoncode: "xhr_error", xhrError: {error: xhr, status, error}});
        }
      }
    );
  };

  this.setupIntent = (token, { callback }) => {
    apiCall(
      "billing/stripe/setupintent",
      { auth: userAuth },
      res => {
        if (callback && typeof callback === "function") {
          if (res.setupIntent && res.setupIntent.payment_method) {
            this.paymentInfo.paymentmethodid = res.setupresult.setupIntent.payment_method;
          }
          callback(res);
        }
      },
      ret => {
        this.handlePaymenterror(ret);
      },
      {
        onXhrError: (xhr, status, error) => {
          this.handlePaymenterror({reasoncode: "xhr_error", xhrError: {error: xhr, status, error}});
        }
      }
    );
  }

  this.setStripePaymentSource = (opt) => {
    toggleLoader("show");
    const self = this;
    opt.shoudUpdateDefaultSource = typeof opt.shoudUpdateDefaultSource !== "undefined" ? opt.shoudUpdateDefaultSource : false;

    if (newCC) {
      Stripe.setPublishableKey(STRIPE_PK);
      const cardData = this.createCardData("number", "exp_month", "exp_year", "name", "cvc");

      Stripe.card.createToken(cardData, (status, tokenInfo) => {
        if (status == 200) {
          self.paymentInfo["token"] = tokenInfo.id;
          self.setupIntent(tokenInfo, {
            callback: (res) => {
              if (res.clientsecret) {
                self.confirmStripeCard(res, opt);
              } else {
                opt.callback(res);
              }
            }
          });
        } else {
          toggleLoader("hide");
          self.handlePaymenterror(tokenInfo.error);
        }
      });
    }
  }

  this.createAlipayPayment = (opt, skipModal) => {
    toggleLoader("show");
    this.paymentInfo.instrument = "alipay";
    const self = this;

    if (opt.showPaymentConfirmationModal && !skipModal) {
      modalManager.open('PaymentConfirmationModal', {
        handleButtonClick: () => {
          self.createAlipayPayment(opt, true);
        },
        handleModalClose: () => {
          toggleLoader("hide");
        }
      });

      return;
    }

    apiCall(
      "paystripe",
      self.paymentInfo,
      data => {
        const {paymentintentsecret} = data;
        const stripe = Stripe('pk_test_rqxCBCYuPZVO5zmwMeD0yEvk');
        stripe.confirmAlipayPayment(paymentintentsecret, {
          // Return URL where the customer should be redirected to after payment
          return_url: window.location.href,
        }).then((result) => {
          if (result.error) {
            self.handlePaymenterror(result);
          }
        });
      },
      ret => {
        self.handlePaymenterror(ret);
      },
      {
        onXhrError: (xhr, status, error) => {
          this.handlePaymenterror({reasoncode: "xhr_error", xhrError: {error: xhr, status, error}});
        }
      }
    );
  }

  this.createWechatPayment = (opt, skipModal) => {
    toggleLoader("show");
    this.paymentInfo.instrument = "wechat";
    const self = this;

    if (opt.showPaymentConfirmationModal && !skipModal) {
      modalManager.open('PaymentConfirmationModal', {
        handleButtonClick: () => {
          self.createWechatPayment(opt, true);
        },
        handleModalClose: () => {
          toggleLoader("hide");
        }
      });

      return;
    }

    apiCall(
      "paystripe",
      this.paymentInfo,
      data => {
        const {paymentintentsecret} = data;
        const stripe = Stripe('pk_test_rqxCBCYuPZVO5zmwMeD0yEvk');

        stripe.confirmWechatPayPayment(
          paymentintentsecret,
          {
            payment_method_options: {
              wechat_pay: {
                client: 'web',
              }
            }
          }, {
            handleActions: false
          }).then(({error, paymentIntent}) => {
            if (error) {
              this.handlePaymenterror(result);
            } else if (paymentIntent && paymentIntent.status === 'requires_action') {
              if (paymentIntent && paymentIntent.next_action) {
                opt.onNextAction(paymentIntent.next_action.wechat_pay_display_qr_code.image_url_png);
              }
            } 
          })
      },
      ret => {
        this.handlePaymenterror(ret);
      },
      {
        onXhrError: (xhr, status, error) => {
          this.handlePaymenterror({reasoncode: "xhr_error", xhrError: {error: xhr, status, error}});
        }
      }
    );
  }
}

export const providers = {
	1:  {name: 'iTunes',      short_name: 'itunes',      type: 'mobile'},
	2:  {name: 'PayPal',      short_name: 'paypal',      type: 'paypal'}, // Old Paypal
	3:  {name: 'Bitcoin',     short_name: 'bitcoin',     type: 'bitcoin'},
	4:  {name: 'Authorize',   short_name: 'authorize',   type: 'authorize'},
	5:  {name: 'Vivacom',     short_name: 'vivacom',     type: 'vivacom'},
	6:  {name: 'Bank',        short_name: 'bank',        type: 'bank'},
	7:  {name: 'PaymentWall', short_name: 'paymentwall', type: 'paymentwall'},
	8:  {name: 'SEP',         short_name: 'sep',         type: 'sep'},
	9:  {name: 'Stripe',      short_name: 'stripe',      type: 'ccard'},
	11: {name: 'PayPal',      short_name: 'paypal',      type: 'paypal'}, // BrainTree
	12: {name: 'Google Play', short_name: 'googleplay',  type: 'mobile'},
	13: {name: 'SafeCharge',  short_name: 'safecharge',  type: 'ccard'},
  15: {name: 'Vivacom',     short_name: 'vivacom',     type: 'vivacom'},
  16: {name: 'Adyen',     short_name: 'adyen',     type: 'ccard'}
};

export const internalProviders = [
  {id: 11,  short_name: 'paypal'},
  {id: 9,  short_name: 'stripe'},
  {id: 13,  short_name: 'safecharge'},
];

export const isInternalPaymentProvider = providerId => internalProviders.find((internalProvider) => internalProvider.id === providerId);

export const getPurchaseData = () => {
	if (typeof(Storage) === 'undefined' || typeof(localStorage) === 'undefined' || localStorage === null) {
		return [];
  }
  
  const purchases = localStorage.getItem("purchases");
  
	if (purchases) {
		return JSON.parse(purchases);
	} else {
		return [];
	}
}

export const storePurchaseDataLocally = (purchaseData) => {
	if (typeof(Storage) === 'undefined' || typeof(localStorage) === 'undefined' || localStorage === null) {
		return;
  }
  
	const purchases = getPurchaseData();

	purchases.push(purchaseData);

	localStorage.setItem("purchases", JSON.stringify(purchases));
}

export const getCreditCardInfo = (paymentSource) => {
  if (!paymentSource) {
    return null;
  }
  
  const paymentProvider = paymentSource.safecharge ? "safecharge" : (paymentSource.stripe ? "stripe" : null);
  
  if (paymentProvider === null) {
    return null;
  }

  const creditCardInfo = paymentSource[paymentProvider].sources && paymentSource[paymentProvider].sources.length > 0 && 
  // Find default source
  paymentSource[paymentProvider].sources.find(pSource => pSource.isdefault) && 
  // If no default is set -> use the last in the array.
  paymentSource[paymentProvider].sources[paymentSource[paymentProvider].sources.length - 1];

  return creditCardInfo;
};

export const brandIcons = {
  visa: <VisaSVG />,
  master_card: <MasterCardSVG />,
  mastercard: <MasterCardSVG />,
  // maestro: <MaestroSVG />,
  american_express: <AmexCardSVG />,
  americanexpress: <AmexCardSVG />,
  dinersclub: <DinersCardSVG />,
  diners_club: <DinersCardSVG />,
  discover: <DiscoverSVG />,
  jcb: <JCBSVG />,
  paypal: <PaypalSVG />,
  default: <DefaultCardSVG />,
  googleplay: <GooglePlaySVG />,
  itunes: <ItunesSVG />,
  vivacom: <VivacomSVG />
};

export const renderBillingProviderOrPaymentSourceIcon = (paymentProvider, paymentSource = null) => {
  let brandIcon = brandIcons["default"];
  if (paymentSource && (paymentProvider.short_name === "safecharge" || paymentProvider.short_name === "stripe")) {
    // Safecharge, paypal or stripe.
    const creditCardInfo = getCreditCardInfo(paymentSource);
    brandIcon = brandIcons[creditCardInfo && creditCardInfo.brand ? creditCardInfo.brand.toLowerCase() : "default"] || brandIcon;
  } else if (paymentProvider.short_name === "paypal") {
    brandIcon = brandIcons["paypal"];
  } else {
    // For example vivacom, googleplay or itunes...
    brandIcon = brandIcons[paymentProvider.short_name] || brandIcon;
  }

  return brandIcon;
};